import React, { useState } from "react";
import "./styles/app.scss";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Topbar from "./components/layout/Topbar";
import HeroLinks from "./components/HeroLinks";
import HaiBlock from "./components/hai/HaiBlock";
import "swiper/css";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import ImageButton from "./components/ImageButton";
import AccessibilityModal from "./components/AccessibilityModal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Head from "./components/Head";

function App() {
  const [highContrast, setHighContrast] = useState("");

  return (
    <div className="App">
      <header className={`App-header ${highContrast}`}>
        <Head />
        <Navbar />
        <Topbar />
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route
                index
                element={
                  <>
                    <HeroLinks />
                    <HaiBlock />
                    <ImageButton />
                  </>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
        <Footer />
        <AccessibilityModal
          setHighContrast={(value: string) => setHighContrast(value)}
        />
      </header>
    </div>
  );
}

export default App;
