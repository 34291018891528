import React from "react";
import BG from "../static/svgs/ImageButtonBG.png";

const ImageButton = () => {
  return (
    <div className="m-auto w-100 mb-3 bg-secondary">
      <div className="m-0 row justify-content-center">
        <div className="imageButton">
          <img className="" src={BG} alt="Hero" />
          <a
            href="/taotleja/select-company"
            className="btn btn-outline-light btn-outline-inverted-vp button"
          >
            Sisene infosüsteemi
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImageButton;
