import React, { useState } from "react";

interface Props {
  setHighContrast: (value: string) => void;
}
const AccessibilityModal = ({ setHighContrast }: Props) => {
  const [contrast, setContrast] = useState("");

  const onOptionChange = (e: any) => {
    setContrast(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setHighContrast(contrast);
  };

  return (
    <div
      className="modal right fade modal-lg-vp"
      id="accessibility-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="accessibility"
    >
      <div className="modal-dialog box-shadow-sm" role="document">
        <div className="modal-content pb-5">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h2>Juurdepääsetavus</h2>
            <p>
              Riigiportaal.ee lehe loomisel on peetud silmas, et siin avaldatav
              info oleks kättesaadav ja kasutatav võimalikult paljudele
              inimestele.
            </p>
            <h3>Teksti suurus</h3>
            <p>
              Kõikides populaarsetes veebilehitsejates on võimalik lehte
              suurendada ja vähendada, kui hoida all Ctrl-klahvi (OS X
              operatsioonisüsteemis Cmd-klahvi) ja samal ajal vajutada kas "+"-
              või "-"-klahvi. Teine mugav võimalus on kasutada hiirt: hoides all
              Ctrl-klahvi ja samal ajal liigutades hiire kerimisrulli. Tagasi
              normaalsuurusesse saab, kui vajutada samaaegselt Ctrl- ja
              0-klahvile.
            </p>
            <h3>Kontrastsus</h3>
            <form name="contrastForm" onSubmit={(event) => handleSubmit(event)}>
              <div className="form-group custom-control custom-radio d-inline-flex">
                <input
                  type="radio"
                  checked={contrast === ""}
                  className="custom-control-input"
                  value=""
                  id="accessRadio3"
                  name="example3"
                  onChange={onOptionChange}
                />
                <label className="custom-control-label" htmlFor="accessRadio3">
                  Vaikimisi seade
                </label>
              </div>
              <div className="form-group custom-control custom-radio d-inline-flex mb-3">
                <input
                  type="radio"
                  checked={contrast === "high-contrast"}
                  className="custom-control-input"
                  value="high-contrast"
                  id="accessRadio4"
                  name="example3"
                  onChange={onOptionChange}
                />
                <label className="custom-control-label" htmlFor="accessRadio4">
                  Must taust ja kollane tekst
                </label>
              </div>
              <button
                type="submit"
                onClick={() => setContrast("")}
                className="btn btn-outline-primary"
              >
                Taasta algseaded
              </button>
              <button type="submit" className="btn btn-primary">
                Kinnita valik
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityModal;
