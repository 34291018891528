import React from "react";

const FooterListGroup = () => {
  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div className="list-group list-group-vp">
        <a href="https://www.konkurentsiamet.ee/et/konkurentsijarelevalve-koondumised/konkurentsijarelevalve/valdkonna-tutvustus#kusimused_vastused" className="list-group-item list-group-item-action">
          Korduma Kippuvad Küsimused
        </a>
        <a
          href="https://www.konkurentsiamet.ee/et/ametist-kontaktid/organisatsioon/dokumendiregister"
          className="list-group-item list-group-item-action"
        >
          Isikuandmete töötlemine
        </a>
        <a href="/" className="list-group-item list-group-item-action">
          Küpsised
        </a>
      </div>
      <p>
        Photo by
        <a
          className="links"
          href={
            "https://unsplash.com/photos/9AxFJaNySB8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          }
        >
          {" T K "}
        </a>
        on
        <a
          className="links"
          href={
            "https://unsplash.com/es/@realaxer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          }
        >
          {" Unsplash"}
        </a>
      </p>
    </div>
  );
};

export default FooterListGroup;
