import React from "react";
import { ReactComponent as Pin } from "../../static/usedIcons/icon_pin.svg";
import { ReactComponent as Phone } from "../../static/usedIcons/icon_phone.svg";
import { ReactComponent as Email } from "../../static/usedIcons/icon_email.svg";
const FooterDetailsList = () => {
  return (
    <ul className="list-unstyled list-vp text-secondary">
      <li>
        <svg className="c-icon">
          <Pin />
        </svg>
        <span className="text-dark">Tatari 39, 10134 Tallinn</span>
      </li>
      <li>
        <svg className="c-icon">
          <Phone />
        </svg>
        <a href="tel:+3726672400" className="text-dark font-weight-normal">
          +372 667 2400
        </a>
      </li>
      <li>
        <svg className="c-icon">
          <Email />
        </svg>
        <a
          href="mailto:info@konkurentsiamet.ee"
          className="text-dark font-weight-normal"
        >
          info@konkurentsiamet.ee
        </a>
      </li>
      <li>
        <p className="text-dark font-weight-bold mb-0">Avatud E-R 8.30-16.00</p>
      </li>
    </ul>
  );
};

export default FooterDetailsList;
