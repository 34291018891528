import React from "react";
import { BodyType } from "../../typings/ComponentTypes";
import { ReactComponent as Check } from "../../static/usedIcons/icon_check.svg";

interface Props {
  data: BodyType;
}

const AccordionPanel = ({ data }: Props) => {
  const { text, heading, listItems, bottomText, button } = data;

  return (
    <>
      {heading && <h4>{heading}</h4>}
      {listItems && (
        <ul className="p-0 mb-2">
          {listItems.map((item, idx) => (
            <li key={`listItem-${idx}`} className="list-item">
              <div className="icon">
                <Check />
              </div>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      )}
      {button && (
        <div className="d-flex mb-2 mx-2">
          <a href={button.link}>{button.linkText}</a>
        </div>
      )}
      {text && <p>{text}</p>}
      {bottomText && (
        <p>
          {bottomText} <a href="#Taotlemine">siit</a>
        </p>
      )}
    </>
  );
};

export default AccordionPanel;
