import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <Helmet>
      <title>HAI - Hinna Arvutamise Infosüsteem</title>
      <link rel="icon" href="../static/favicon.ico" />
    </Helmet>
  );
};

export default Head;
