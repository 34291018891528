import React from "react";
const HaiMid = () => {
  return (
    <div className="mid">
      <h4>
        HAI süsteemi saab sisse logida ID-kaardi, mobiil-ID, Smart-ID või EU
        eID-ga.
      </h4>
      <h4>Head kasutamist!</h4>
    </div>
  );
};

export default HaiMid;
