import React from "react";
import { ReactComponent as LinkedIn } from "../../static/usedIcons/icon_linkedin.svg";

const SocialIcons = () => {
  return (
    <ul className="list-group list-group-horizontal-sm list-social-vp">
      <li className="list-group-item">
        <a
          href="https://www.linkedin.com/company/konkurentsiamet-estonian-competition-authority/"
          className="d-flex align-items-center justify-content-center border-0"
        >
            <LinkedIn className="round-icon"/>
        </a>
      </li>
    </ul>
  );
};

export default SocialIcons;
