import React from "react";
import SocialIcons from "./SocialIcons";
import FooterDetailsList from "./FooterDetailsList";
import FooterListGroup from "./FooterListGroup";

const Footer = () => {
  return (
    <footer className="w-100 site-footer-vp d-print-none">
      <div className="container-body-vp container">
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <h4>Püsi kursis!</h4>
            <p>Sotsiaalmeedias.</p>
            <SocialIcons />
          </div>

          <div className="col-sm-6 col-md-4">
            <h4>Konkurentsiamet</h4>
            <FooterDetailsList />
          </div>
          <div className="col-sm-6 col-md-4">
            <FooterListGroup />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
