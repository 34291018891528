import React from "react";
import Hero from "../static/svgs/hero.jpg";
import { ReactComponent as Heat } from "../static/svgs/Heat.svg";
import { ReactComponent as Water } from "../static/svgs/Water.svg";
import { ReactComponent as Gas } from "../static/svgs/Gas.svg";
import { ReactComponent as Electricity } from "../static/svgs/Electricity.svg";
const HeroLinks = () => {
  return (
    <div>
      <div className="container container-body-lg-vp">
        <div className="m-auto w-100 mb-3">
          <div className="m-0 row hero">
            <div className="image">
              <img className="" src={Hero} alt="Hero" />
            </div>
            <div
              className="nav d-flex flex-column tabs font-weight-bold"
              id="nav-tab"
            >
              <a className="item guide-head" id="nav-home-tab" href="#heat">
                <div className="icon">
                  <Heat className="icon-props" />
                </div>
                Soojuse valdkond
              </a>
              <a className="item guide-head" id="nav-profile-tab" href="#water">
                <div className="icon">
                  <Water className="icon-props" />
                </div>
                Vee valdkond
              </a>
              <a className="item guide-head" id="nav-contact-tab" href="#gas">
                <div className="icon">
                  <Gas className="icon-props" />
                </div>
                Gaasi valdkond
              </a>
              <a
                className="item guide-head"
                id="nav-contact-tab"
                href="#electricity"
              >
                <div className="icon">
                  <Electricity className="icon-props" />
                </div>
                Elektri valdkond
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroLinks;
