import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";
import AccordionFirst from "../../static/svgs/Accordion_slide_1.png";
import AccordionSecond from "../../static/svgs/Accordion_slide_2.png";
import { ReactComponent as ArrowLeft } from "../../static/usedIcons/icon_arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../static/usedIcons/icon_arrow_right.svg";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/css/effect-fade";

const HaiCarousel = () => {
  const [index, setIndex] = useState(1);
  return (
    <div className="row my-4 d-block">
      <div className="container-body-vp container-body-sm-vp container">
        <div className=" mb-2 justify-content-between w-100 single-item-carousel-container-vp">
          <div className=" align-items-center justify-content-between h-100 mb-2">
            <Swiper
              effect="fade"
              slidesPerView={1}
              navigation={{
                nextEl:
                  "#carousel-single-item-controls .vp-slide-control-right",
                prevEl: "#carousel-single-item-controls .vp-slide-control-left",
              }}
              modules={[Navigation, EffectFade]}
              className="mySwiper"
              onSlideChange={(e) => {
                setIndex(e.activeIndex + 1);
              }}
            >
              <SwiperSlide className="swiper-slide swiper-slide-lg-vp">
                <img src={AccordionFirst} alt="" />
              </SwiperSlide>
              <SwiperSlide className="swiper-slide swiper-slide-lg-vp">
                <img src={AccordionSecond} alt="" />
              </SwiperSlide>
              <div
                id="carousel-single-item-controls"
                className="vp-slide-controls carousel-single-item-controls align-items-center ml-2 swiper-controls-layout swiper-button"
              >
                <span
                  role="button"
                  className="vp-slide-control-right d-flex justify-content-center align-items-center swiper-button"
                >
                  <ArrowRight />
                </span>
                <div className="slide-count-vp py-2 text-secondary d-flex justify-content-center align-items-center">
                  <span className="current-slide-vp font-weight-bold">
                    {index}
                  </span>
                  <span className="separator px-1">|</span>
                  <span className="all-slides-vp">2</span>
                </div>
                <span
                  role="button"
                  className="vp-slide-control-left d-flex justify-content-center align-items-center swiper-button"
                >
                  <ArrowLeft className="" />
                </span>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HaiCarousel;
