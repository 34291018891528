import { AccordionDataType } from "../typings/ComponentTypes";

export const haiAccordionData: AccordionDataType = {
  title: "Taotlemine HAI kaudu",
  accordion: [
    {
      id: "1",
      heading: "Taotluse täitmine ja esitamine",
      body: [
        {
          text: "Kui ettevõtjana soovid kooskõlastada hindu või tasusid lühimenetluse vormis, siis palun täida HAI keskkonnas selle kohta taotlus ja põhjenda oma soovi.",
        },
        {
          text: "HAI-sse sisenemiseks pead olema keskkonna kasutaja. Ettevõtte B-kaardil olevad isikud on automaatselt kasutajad, teised kasutajad saab esindusõiguslik isik keskkonnas ise määrata.",
        },
        {
          text: "Süsteemis tuleb esmalt ära täita ettevõtte püsiandmed valdkondade kaupa (kui tegutsetakse mitmes valdkonnas). Püsiandmed on isiku ja ettevõtte kontaktandmed, mida kasutatakse Konkurentsiametiga suhtlemiseks. Samuti on püsiandmeteks valdkondade põhised andmed, mida kasutatakse eeltäidetud taotluste põhjade loomiseks.",
        },
        {
          text: "Kui püsiandmed on täidetud, juhatatakse kasutaja töölauale, kust saab alustada valdkonnapõhist taotluse täitmist – põhjendada taotluse vajadust, sisestada muutunud hinnad ja laadida üles hinnamuutust kinnitavad dokumendid (võetud pakkumised, uued lepingud vm).",
        },
        {
          text: "Taotluse esitamiseks on vajalik esindusõigusliku või volitatud isiku kinnitus.",
        },
      ],
    },
    {
      id: "2",
      heading: "taotluse Nõuetekohasuse kontrollimine",
      body: [
        {
          text: "Konkurentsiametis määratakse taotlusele menetleja, kes kontrollib esmalt taotluse nõuetekohasust. Kui ettevõtja ei selgita ega põhjenda hinna moodustamise aluseid, mis on vajalikud taotletava hinna kooskõlastamiseks, võib Konkurentsiamet lugeda esitatud taotluse puudustega taotluseks ega alusta selle menetlemist enne puuduste likvideerimist.",
        },
      ],
    },
    {
      id: "3",
      heading: "taotluse menetlemine",
      body: [
        {
          text: "Nõuetele vastava taotluse korral analüüsib menetleja taotluses esitatud andmeid. Vajadusel esitab ettevõtjale lisaküsimusi. Kogu vajalik suhtlus ja andmete täpsustamine toimub süsteemi siseselt. Teavitused ja päringud saadetakse püsiandmetes märgitud e-posti aadressile.",
        },
      ],
    },
    {
      id: "4",
      heading: "Taotluse kooskõlastamine, otsuse koostamine ja esitamine",
      body: [
        {
          text: "Põhjendatud taotluse korral kooskõlastab Konkurentsiamet taotletava(d) hinna(d), koostab otsuse ja esitab selle ettevõtjale. Otsus on kättesaadav süsteemis.",
        },
        {
          text: "Ettevõtjal on kohustus avaldada rakendatavad hinnad juhindudes vastava valdkonna eriseaduses toodud nõuetest ning soovitavalt ka oma koduleheküljel. Konkurentsiamet avaldab kooskõlastatud hinnad oma koduleheküljel.",
        },
      ],
    },
  ],
};

export const fieldAccordionData: AccordionDataType = {
  title: "Valdkonnad",
  accordion: [
    {
      id: "heat",
      heading: "soojuse valdkond",
      body: [
        { heading: "Täistaotlus MS Exceli vormil tuleb esitada kui:" },
        {
          listItems: [
            "Soojuse piirhind ei ole varasemalt kooskõlastatud Konkurentsiametiga,",
            "viimasest täisanalüüsist on möödas rohkem kui kolm aastat,",
            "hinnakomponendid muutuvad oluliselt (müügimaht, kulud, investeeringud vm),",
            "puudub kehtiv hinnavalem.",
          ],
        },
        {
          button: {
            linkText: "Juhendmaterjalid täistaotluse esitamiseks",
            link: "https://www.konkurentsiamet.ee/et/vesi-soojus/soojus/hindade-kooskolastamine",
          },
        },
        {
          text: "Lühitaotlust saab esitada HAI süsteemis kehtiva hinnavalemi korral üksiku hinnakomponendi muutumisel.",
        },
        { bottomText: "Täpsemalt saad HAI-s taotlemise kohta lugeda " },
      ],
    },
    {
      id: "water",
      heading: "Vee valdkond",
      body: [
        { heading: "Täistaotlus MS Exceli vormil tuleb esitada kui:" },
        {
          listItems: [
            "hinnakomponendid muutuvad oluliselt (müügimaht, kulud, investeeringud vm),",
            "soovitakse muuta põhiteenuste hinnakirjas teenuste nimetusi,",
            "soovitakse muuta põhiteenustega seotud teenuseid või nende hindu.",
          ],
        },
        {
          button: {
            linkText: "Juhendmaterjalid täistaotluse esitamiseks",
            link: "https://www.konkurentsiamet.ee/et/vesi-soojus/vesi/hindade-kooskolastamine",
          },
        },
        { heading: "Lühitaotlust saab esitada HAI süsteemis kui:" },
        {
          listItems: [
            "muutub elektrienergia hind,",
            "muutub teiselt vee-ettevõtjalt ostetava teenuse hind,",
          ],
        },
        { bottomText: "Täpsemalt saad HAI-s taotlemise kohta lugeda " },
      ],
    },
    {
      id: "gas",
      heading: "gaasi valdkond",
      body: [
        { heading: "Täistaotlus MS Exceli vormil tuleb esitada kui:" },
        {
          listItems: [
            "osutatakse ülekandeteenust,",
            "jaotusteenuse osutajal muutuvad olulised hinnakomponendid (müügimaht, kulud, investeeringuid vm),",
          ],
        },
        {
          button: {
            linkText: "Juhendmaterjalid täistaotluse esitamiseks",
            link: "https://www.konkurentsiamet.ee/et/elekter-maagaas/maagaas/vorguteenuse-hindade-kooskolastamine",
          },
        },
        { heading: "Lühitaotlust saab esitada HAI süsteemis kui:" },
        {
          listItems: [
            "muutub teiselt ettevõtjalt ostetava teenuse hind,",
            "muutub gaasi hind,",
          ],
        },
        { bottomText: "Täpsemalt saad HAI-s taotlemise kohta lugeda " },
      ],
    },
    {
      id: "electricity",
      heading: "elektri valdkond",
      body: [
        { heading: "Täistaotlus MS Exceli vormil tuleb esitada kui:" },
        {
          listItems: [
            "osutatakse ülekandeteenust,",
            "jaotusteenuse osutajal muutuvad olulised hinnakomponendid (müügimaht, kulud, investeeringuid vm),",
          ],
        },
        {
          button: {
            linkText: "Juhendmaterjalid täistaotluse esitamiseks",
            link: "https://www.konkurentsiamet.ee/et/elekter-maagaas/elekter/vorgutasude-kooskolastamine",
          },
        },
        { heading: "Lühitaotlust saab esitada HAI süsteemis kui:" },
        {
          listItems: [
            "muutub teiselt ettevõtjalt ostetava teenuse hind,",
            "muutub elektrienergia hind,",
          ],
        },
        { bottomText: "Täpsemalt saad HAI-s taotlemise kohta lugeda " },
      ],
    },
  ],
};
