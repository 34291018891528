import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as Plus } from "../../static/usedIcons/icon_accordion_plus.svg";
import { ReactComponent as Minus } from "../../static/usedIcons/icon_accordion_minus.svg";
import "bootstrap/dist/js/bootstrap.min.js";

import { AccordionDataType } from "../../typings/ComponentTypes";
import AccordionPanel from "./AccordionPanel";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

interface Props {
  id?: string;
  data: AccordionDataType;
}

const HaiAccordion = ({ data, id }: Props) => {
  const location = useLocation().hash;
  const [openedAccordion, setOpenedAccordion] = useState<string[]>([]);
  const [collapsedAll, setCollapsedAll] = useState<boolean | undefined>(false);
  const { title, accordion } = data;

  const allItemsArray = useMemo(
    () => data.accordion.map((item) => item.id),
    [data]
  );

  const handleAll = () => {
    if (collapsedAll) {
      setOpenedAccordion([]);
    } else {
      setOpenedAccordion(allItemsArray);
    }
  };

  const handleOne = (id: string) => {
    if (openedAccordion.find((item) => item === id)) {
      setOpenedAccordion(openedAccordion.filter((item) => item !== id));
    } else {
      setOpenedAccordion([...openedAccordion, id]);
    }
  };

  useEffect(() => {
    if (location === "#Taotlemine" && id === "Taotlemine") {
      setOpenedAccordion(allItemsArray);
    } else if (location) {
      setOpenedAccordion([...openedAccordion, location.slice(1)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="hai-accordion" id={id || ""}>
      <div className="text-block">
        <h4>{title}</h4>
      </div>
      <div className="my-4 accordion-block">
        <button
          className="btn-accordion-block"
          onClick={() => {
            setCollapsedAll(!collapsedAll);
            handleAll();
          }}
        >
          {!collapsedAll ? (
            <>
              <Plus className="plus-icon" /> Ava Kõik
            </>
          ) : (
            <>
              <Minus /> Sule Kõik
            </>
          )}
        </button>
        <Accordion
          className="w-100 collapse-vp"
          alwaysOpen
          activeKey={openedAccordion}
        >
          {accordion.map((item, idx) => {
            return (
              <Accordion.Item
                id={item.id}
                eventKey={item.id}
                key={`accordionItem-${idx}`}
                className="card"
              >
                <Accordion.Header
                  as={"div"}
                  className="collapse-header-vp text-primary"
                  onClick={() => handleOne(item.id)}
                >
                  <div className="btn btn-link">
                    <h6>{item.heading}</h6>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="paragraph-class">
                  {item.body.map((item, textIdx) => (
                    <AccordionPanel
                      key={`accordion-${idx}-${textIdx}`}
                      data={item}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default HaiAccordion;
