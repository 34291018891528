import React from "react";
import Logo from "../../static/svgs/LogoBlock.png";

const Topbar = () => {
  return (
      <div className="container-body-vp container d-flex m-auto p-0">
        <div className="topbar nav-middle-vp d-flex justify-content-between m-0 px-0 w-100">
          <a className="navbar-brand" href="https://www.konkurentsiamet.ee/et">
            <img src={Logo} alt="smth" />
          </a>
            <div>
            <a
              href="/taotleja/select-company"
              className="btn btn-primary mx-1"
            >
              <span className="">Sisene</span>
            </a>
            <a
              href="/register/application"
              className="btn btn-primary"
            >
              <span className="">Dokumendiregister</span>
            </a>
          </div>
        </div>
      </div>
  );
};

export default Topbar;
