import React from "react";
import HaiCarousel from "./HaiCarousel";
import HaiMid from "./HaiMid";
import HaiTop from "./HaiTop";
import HaiAccordion from "./HaiAccordion";
import {
  fieldAccordionData,
  haiAccordionData,
} from "../../utils/ComponentData";

const HaiBlock = () => {
  return (
    <div>
      <div className="container hai">
        <HaiTop />
        <HaiCarousel />
        <HaiAccordion data={fieldAccordionData} />
        <HaiAccordion data={haiAccordionData} id="Taotlemine" />
        <HaiMid />
      </div>
    </div>
  );
};

export default HaiBlock;
