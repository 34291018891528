import React from "react";

const HaiTop = () => {
  return (
    <div className="pt-4 top">
      <div className="upper">
        <h1>Tere tulemast!</h1>
        <p className="font-size-lg">
          Olete sisenemas Konkurentsiameti reguleeritavate hindade arvutamise
          infosüsteemi HAI
        </p>
        <p>
          Tarbijate ja ettevõtjate huvide tasakaalustamiseks on oluline, et
          monopoolsete teenuste hinnaregulatsiooni viib läbi sõltumatu
          regulaator, kelleks Eestis on määratud Konkurentsiamet. Monopoolsete
          teenuste hindade põhjendatuse kontrollimiseks on kehtestatud
          eriseadused (
          {
            <a
              className="links"
              href={"https://www.riigiteataja.ee/akt/107032023067?leiaKehtiv"}
            >
              {"elektrituruseadus"}
            </a>
          }
          ,
          {
            <a
              className="links"
              href={"https://www.riigiteataja.ee/akt/109082022021?leiaKehtiv"}
            >
              {" maagaasiseadus"}
            </a>
          }
          ,
          {
            <a
              className="links"
              href={"https://www.riigiteataja.ee/akt/KK%C3%BCtS"}
            >
              {" kaugkütteseadus "}
            </a>
          }
          ning
          {
            <a
              className="links"
              href={"https://www.riigiteataja.ee/akt/127092023004?leiaKehtiv"}
            >
              {" ühisveevärgi ja -kanalisatsiooni seadus"}
            </a>
          }
          ), mis sätestavad hindade kujundamise aluspõhimõtted. Teenuse hind
          peab katma põhjendatud kulutused, vajalikud investeeringud ning
          mõistliku kasumi.
        </p>
      </div>

      <h3>Hindade kooskõlastamine</h3>
      <div>
        <p>
          Selleks, et elektri- ja gaasi võrguteenuste, soojuse ja veeteenuse
          hinnad oleksid põhjendatud ja mõistlikud, tuleb need kooskõlastada
          Konkurentsiametiga.
        </p>
        <p>
          Esmakordsel hindade taotlemisel või ka varasemalt kooskõlastatud
          teenuse hinda mõjutavate hinnakomponentide (tehnilised näitajad,
          müügikogused, kulud, kütuste liigid) olulisel muutumisel tuleb
          ettevõtjal täita taotlus (täistaotlus) ettevõtte või piirkonna
          tehniliste andmete ja finantsnäitajatega. Koos taotlusega tuleb
          esitada selgitused hinnakomponentide prognoosimise põhimõtete kohta ja
          lisada täiendavad dokumendid (võetud pakkumised, sõlmitud lepingud
          jm). Konkurentsiamet viib läbi analüüsi (täismenetlus) ning väljastab
          otsuse.
        </p>
        <p>
          Konkurentsiametiga juba eelnevalt kooskõlastatud teenuse hinna või
          kehtiva hinnavalemi (soojuse valdkonnas) olemasolul saab üksiku
          hinnakomponendi muutumisel (näiteks elektrienergia hind, kütuse hind,
          ostetava teenuse hind vm) kooskõlastada teenuse hinda esitades
          taotluse lühivormis (lühitaotlus), milles muutuvateks teguriteks on
          ainult vastava komponendi hind. Lühitaotluse esitamisel analüüsib
          (lühimenetlus) Konkurentsiamet ainult üksikuid muutuvaid
          hinnakomponente.
        </p>
      </div>
    </div>
  );
};

export default HaiTop;
